<template>
  <!-- first row -->
  <div class="row">
    <div class="col-lg-12">
      <!-- <div class="row text-center p50">
        <div class="col-sm-2">
          <h5>Untuk Kegunaan Pejabat</h5>
        </div>
      </div> -->
      <div class="row text-center p50">
        <!-- <div class="col-sm">
          <a>
            <card>
              <router-link to="/maintenance">
                <button class="btn btn-link">
                  <img src="@/assets/img/maintenance2.png" width="50px" />
                </button>
              </router-link>
            </card>
          </a>
        </div> -->
        
        <div class="col-lg" style="padding-top: 10px;text-align: left;right:5%">
          <!-- tandas ok -->
          <div class="head">
            <h1>Tandas OK?</h1>
          </div>
        </div>
      </div>

      <div class="row text-center p50">
        
        <!-- <div class="col-lg-6">
          <card type="chart" style="height: 95%; padding: 30%;">
            <template slot="header">
              <div class="happy">
                <button class="btn btn-link" v-on:click="perasaan()">
                  <img
                    src="@/assets/img/smile.png"
                    width="100%"
                    align="middle"
                    alt="Responsive image"
                  />
                </button>
              </div>
            </template>
          </card>
        </div> -->

        <div class="col-lg-12">
          <card type="chart">
            <template slot="header">
              <!-- <h2 class="card-title">COMPLAINT RESPONSIVENESS</h2> -->
              <form class="circle-container">
                <!-- <div class="row"> -->
                <!-- <div id="main">
                    <div></div>
                    <div class="sad" id="mysmileface" >
                      <button class="btn btn-link">
                        <img src="@/assets/img/sad.png" class="img-circle" alt="Responsive image">
                      </button>
                    </div>
                    <div></div>
                </div>-->
                <div class="row">
                  <!-- one big row -->
                  <div class="col-sm-12">
                    <!-- one big column -->
                    <div class="row">
                      <!-- first row -->
                      <div class="col-sm-1"></div>
                      <div class="col-sm-2">
                        <!-- first row first column -->

                        <!-- first row first column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- first row second column -->

                        <!-- first row second column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- first row third column -->
                        <!-- busuk -->
                        <base-button class="btn btn-link" @click="submit('1')">
                        <div class="row">
                          <div class="col-lg">
                          <img src="@/assets/img/Busuk Icon.png" width="100%"/>
                          </div>
                          <div class="col-lg gap" >
                          <a>Busuk</a>
                          </div>
                        </div>
                        </base-button>
                        <!-- <label>
                          <input type="checkbox" value="h" v-model="form.checkbox" /> 
                        </label> -->
                            
                        
                        <!-- first row third column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- first row fourth column -->

                        <!-- first row fourth column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- first row fifth column -->

                        <!-- first row fifth column -->
                      </div>
                      <div class="col-sm-1"></div>

                      <!-- first row -->
                    </div>

                    <div class="row">
                      <!-- second row -->
                      <div class="col-sm-1"></div>
                      <div class="col-sm-2">
                        <!-- second row first column -->

                        <!-- second row first column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- second row second column -->
                        <!-- Bin Sanitari Penuh-->
                        <base-button class="btn btn-link" @click="submit('6')">
                        <div class="row">
                          <div class="col-lg">
                            <img src="@/assets/img/Bin Sanitari Icon.png" width="100%"/>
                          </div>
                          <div class="col-lg gap">
                            <a>Bin Sanitari Penuh</a>
                          </div>
                        </div>
                        </base-button>
                        <!-- second row second column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- second row third column -->

                        <!-- second row third column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- second row fourth column -->
                        <!-- Tisu Habis -->
                        <base-button class="btn btn-link" @click="submit('8')">
                        <div class="row">
                          <div class="col-lg">
                            <img src="@/assets/img/Tisu Habis Icon.png" width="100%"/>
                          </div>
                          <div class="col-lg gap">
                            <a>Tisu Habis</a>
                          </div>
                        </div>
                        </base-button>
                        <!-- second row fourth column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- second row fifth column -->

                        <!-- second row fifth column -->
                      </div>
                      <div class="col-sm-1"></div>

                      <!-- second row -->
                    </div>

                    <div class="row">
                      <!-- third row -->
                      <div class="col-sm-1"></div>
                      <div class="col-sm-2">
                        <!-- third row first column -->
                        <!-- Tong Sampah Penuh -->
                        <base-button class="btn btn-link" @click="submit('5')">
                        <div class="row">
                          <div class="col-lg">
                          <img src="@/assets/img/Tong Sampah Penuh Icon.png" width="100%"/>
                          </div>
                          <div class="col-lg gap">
                          <a>Tong Sampah Penuh</a>
                          </div>
                        </div>
                        </base-button>
                        <!-- third row first column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- third row second column -->

                        <!-- third row second column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- third row third column -->
                        <base-button class="btn btn-link" disabled>
                        <div class="row">
                          <div class="col-lg">
                          <img src="@/assets/img/sad.png" width="100%"/>
                          </div>
                          <div class="col-lg gap">
                          <a>Tandas OK?</a>
                          </div>
                        </div>
                        </base-button>
                        <!-- </div>  -->
                        <!-- third row third column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- third row fourth column -->

                        <!-- third row fourth column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- third row fifth column -->
                        <!-- Lantai Basah / kotor -->
                        <base-button class="btn btn-link" @click="submit('2')">
                        <div class="row">
                          <div class="col-lg">
                          <img src="@/assets/img/Lantai Licin Kotor Icon.png" width="100%"/>
                          </div>
                          <div class="col-lg gap">
                          <a>Lantai Licin / Kotor</a>
                          </div>
                        </div>
                        </base-button>
                        <!-- third row fifth column -->
                      </div>
                      <div class="col-sm-1"></div>

                      <!-- third row -->
                    </div>

                    <div class="row">
                      <!-- fourth row -->
                      <div class="col-sm-1"></div>
                      <div class="col-sm-2">
                        <!-- fourth row first column -->

                        <!-- fourth row first column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- fourth row second column -->
                        <!-- Tandas tersumbat -->
                        <base-button class="btn btn-link" @click="submit('7')">
                        <div class="row">
                          <div class="col-lg">
                          <img src="@/assets/img/Tandas Basah Icon.png" width="100%"/>
                          </div>
                          <div class="col-lg gap">
                          <a>Tandas Sumbat</a>
                          </div>
                        </div>
                        </base-button>
                        <!-- fourth row second column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- fourth row third column -->

                        <!-- fourth row third column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- fourth row fourth column -->
                        <!-- Paip Bocor -->
                        <base-button class="btn btn-link" @click="submit('3')">
                        <div class="row">
                          <div class="col-lg">
                          <img src="@/assets/img/Paip Bocor Icon.png" width="100%"/>
                          </div>
                          <div class="col-lg gap">
                          <a>Paip Bocor</a>
                          </div>
                        </div>
                        </base-button>
                        <!-- fourth row fourth column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- fourth row fifth column -->

                        <!-- fourth row fifth column -->
                      </div>
                      <div class="col-sm-1"></div>

                      <!-- fourth row -->
                    </div>

                    <div class="row">
                      <!-- fifth row -->
                      <div class="col-sm-1"></div>
                      <div class="col-sm-2">
                        <!-- fifth row first column -->

                        <!-- fifth row first column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- fifth row second column -->

                        <!-- fifth row second column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- fifth row third column -->
                        <!--Sabun Habis  -->
                        <base-button class="btn btn-link" @click="submit('4')">
                        <div class="row">
                          <div class="col-lg">
                          <img src="@/assets/img/Sabun Habis Icon.png" width="100%"/>
                          </div>
                          <div class="col-lg gap">
                          <a>Sabun Habis</a>
                          </div>
                        </div>
                        </base-button>
                        <!-- fifth row third column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- fifth row fourth column -->

                        <!-- fifth row fourth column -->
                      </div>
                      <div class="col-sm-2">
                        <!-- fifth row fifth column -->

                        <!-- fifth row fifth column -->
                      </div>
                      <div class="col-sm-1"></div>

                      <!-- fourth row -->
                    </div>
                    <!-- <div class="row">
                      <base-button class="btn btn-primary centerI"  v-on:click="submit()">Hantar</base-button>
                    </div> -->
                    <!-- one big column -->
                  </div>
                  <!-- one big row -->
                </div>
              </form>
            </template>
            <!-- sad -->
          </card>
        </div>
      </div>
    </div>
  </div>
  <!-- first row -->
</template>
<script>
import swal from "sweetalert2";
import { isMobile } from 'mobile-device-detect';

export default {
  data: function() {
    return {
      userA: isMobile ? 'Welcome to Your Vue.js mobile App!' : 'Welcome to Your Vue.js App',
      nilai: "sad",
      form: {
        checkbox: []
      },
      a1: [],
      b1: [],
      c1: [],
      d1: [],
      e1: [],
      f1: [],
      g1: [],
      h1: [],
    };
  },
  methods: {
    getUserAgent(){
      // var userAgenta = navigator.userAgent 
      // if (userAgenta === navigator.userAgent.match(/Android/i)){
      //   this.userA = userAgenta
      //   console.log(this.userA)
      // }
      // else {
      //   console.log("web view")
      // }
      // console.log(userAgenta)
    },
    // check: function(e) {
    //   console.log(this.form.checkbox)
    //   const feed = [];

    //   fetch("http://35.198.219.94/masa", {
    //     method: "GET",
    //     // body: JSON.stringify(data)
    //     //hantar data je weeh !!!! lk
    //   })
    //     .then(response => {
    //       // eslint-disable-next-line no-console
    //       return response.json();
    //       // console.log(body);
    //   });
    // },
    perasaan() {
      this.nilai = "happy";
      let data = {
        stress: this.nilai
      };
      console.log(data);
      swal({
        title: `Terima Kasih.`,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-primary"
      });
    },
    mounted() {
      console.log(this.is)
    },
    created() {
      
      this.getUserAgent();
    },
    submit(value) {
      console.log("value " + value)
      let keydata = "formdata"

      let data = {
        key: keydata,
        value: value
      }

      var json_data = JSON.stringify(data)

      console.log("json data " + json_data)
      
      this.$mqtt.publish("jkr/buttondata",json_data)
      // let currentDate = new Date().toLocaleString();
      // console.log(currentDate);

      // let a = "0";
      // let b = "0";
      // let c = "0";
      // let d = "0";
      // let e = "0";
      // let f = "0";
      // let g = "0";
      // let h = "0";

      swal({
        title: `Terima kasih diatas aduan yang dibuat. Kami akan mengambil tindakan sewajarnya`,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-primary",
        type: "success",
        timer: 2000
      });

      //noti
      // this.$swal('heading','this is heading','ok');

      // console.log(this.form.checkbox);
      // for (let i = 0; i < this.form.checkbox.length; i++) {
      //   console.log(this.form.checkbox[i]);
      //   if (this.form.checkbox[i] == "a") {
      //     a = "1";
      //     this.a1.push(a,currentDate);
      //     console.log(this.a1)
      //   } else if (this.form.checkbox[i] == "b") {
      //     b = "1";
      //     this.b1.push(b,currentDate);
      //     console.log(this.b1)
      //   } else if (this.form.checkbox[i] == "c") {
      //     c = "1";
      //     this.c1.push(c,currentDate);
      //     console.log(this.c1)
      //   } else if (this.form.checkbox[i] == "d") {
      //     d = "1";
      //     this.d1.push(d,currentDate);
      //     console.log(this.d1)
      //     for (i=0;i<3;i++){
      //       localStorage.setItem(this.d1);
      //       let Ltime = this.currentDate - this.d1.currentDate
      //       console.log(Ltime);
      //     }
      //     localStorage.removeItem(this.id);
      //   } else if (this.form.checkbox[i] == "e") {
      //     e = "1";
      //     this.e1.push(e,currentDate);
      //     console.log(this.e1)
      //   } else if (this.form.checkbox[i] == "f") {
      //     f = "1";
      //     this.f1.push(f,currentDate);
      //     console.log(this.f1)
      //   } else if (this.form.checkbox[i] == "g") {
      //     g = "1";
      //     this.g1.push(g,currentDate);
      //     console.log(this.g1)
      //   } else if (this.form.checkbox[i] == "h") {
      //     h = "1";
      //     this.h1.push(h,currentDate);
      //     console.log(this.h1)
      //   }
        
      // }

      

      // let data = {
      //   stress: this.nilai,
      //   id: "123",
      //   sani: a,
      //   tisuhabis: b,
      //   sampenuh: c,
      //   lantaibasah: d,
      //   tansumbat: e,
      //   bocor: f,
      //   sabunhabis: g,
      //   busuk: h
      // };
      // console.log(data);
      // fetch("http://35.198.219.94/send", {
      //   method: "POST",
      //   body:JSON.stringify(data)
        // body: JSON.stringify(data) //hantar data je weeh !!!! lk
        
      // }).then(response => {
        // eslint-disable-next-line no-console
      //   return response.json();
      // });
      // fetch("http://35.198.219.94/time", {
      //   method: "POST",
      //   body:JSON.stringify(data)
      //   // body: JSON.stringify(data) //hantar data je weeh !!!! lk
        
      // }).then(response => {
      //   // eslint-disable-next-line no-console
      //   return response.json();
        
      // });
      // console.log('Reseting the form')
    
        // Reset our form values
        // this.form.checkbox = null
    },
    clear() {}
  }
};
</script>
<style>
.gap{
  padding-top: 30px;
}
.centerI{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.head {
  text-align: center;
  /* padding-right: 50px; */
  /* margin-top: -110px;
  min-height: 110px; */
}
#main {
  /* width: 100%;
  height: 600px; */
  /* border: 1px solid black; */
  /* display: -webkit-flex;  */
  /* Safari */
  /* -webkit-align-items: flex-start;  */
  /* Safari 7.0+ */
  /* display: flex;
  align-items: flex-start; */
}
#main div {
  /* -webkit-flex: 1;  */
  /* Safari 6.1+ */
  /* flex: 1; */
}

#mysmileface {
  /* -webkit-align-self: center;  */
  /* Safari 7.0+ */
  /* align-self: center; */
}
.circle-container {
  padding: 40px;
  /* list-style: none; */
  /* position: relative; */
  /* width: 50em; */
  /* height: 50em; */
  /* padding: 2.8em; */
  /*2.8em = 2em*1.4 (2em = half the width of a link with img, 1.4 = sqrt(2))*/
  /* border: dashed 1px; */
  /* border-radius: 50%; */
  /* margin: 1.75em auto 0; */
  /* background-color: white; */
}
.circle-container a {
  /* display: block;
  position: absolute;
  top: 43%;
  left: 50%;
  width: 6em;
  height: 3em;
  margin: -4em; */
}
.circle-container img {
  display: block;
}
.deg0 {
  /* transform: translate(18em); */
} /* 12em = half the width of the wrapper */
.deg45 {
  /* transform: rotate(45deg) translate(18em) rotate(-45deg); */
}
.deg90 {
  /* transform: rotate(90deg) translate(18em) rotate(-90deg); */
}
.deg135 {
  /* transform: rotate(135deg) translate(18em) rotate(-135deg); */
}
.deg180 {
  /* transform: rotate(180deg) translate(18em) rotate(-180deg); */
}
.deg225 {
  /* transform: rotate(225deg) translate(18em) rotate(-225deg); */
}
.deg270 {
  /* transform: rotate(270deg) translate(18em) rotate(-270deg); */
}
.deg315 {
  /* transform: rotate(315deg) translate(18em) rotate(-315deg); */
}
.happy {
  position: absolute;
  left: 0;
  top: 40%;
  width: 100%;
  text-align: center;
}
</style>
