export default {
  colors: {
    default: '#F4D03F',
    primary: '#F4D03F',
    info: '#1d8cf8',
    danger: '#fd5d93',
    orange: '#ff8a76',
    teal: '#F4D03F',
    red: '#ff6961',
    green: '#77dd77',
    purple: '#b39eb5', 
    choc: '#836953',
    primaryGradient: [
      'rgba(214, 203, 0, 0.1)', 
      'rgba(53, 183, 125, 0)',
      'rgba(119,52,169,0)'
    ],
    purpleGradient: ['rgba(253,93,147,0.8)', 'rgba(253,93,147,0)'],
    dashboardGradient: [
      'rgba(51,255,255,0.4)',
      'rgba(255,255,0,0.5)',
      'rgba(102,204,255,0.1)'
    ]
  }
};
