<template>
  <!-- first big row -->
  <div class="row" style="padding-top: 15px">
    
    <!-- one big column -->
    <div class="col-lg">
      <!-- overview row -->
      <div class="row">
        <div class="col-lg header">
          <h1 class="title">OVERVIEW</h1>
          <h1 class="title" style="color: #FAD01A">  |  </h1>
          <h3 v-html="date" class="title"></h3>
          <h1 class="title" style="color: #FAD01A">  |  </h1>
          <h3 v-html="time" class="title"></h3>
        </div>
        <!-- <div class="col-lg" style="text-align: right">
          <base-button icon type="primary" @click="modal.site = true">
          <i class="fas fa-ellipsis-v" style="text-align: center; color: #ffffff; font-size: 20px;"></i>
          </base-button>
        </div> -->
      </div>
      <div class="row">
        <div class="col-lg-3">
          <h4>
            Select site:
            <el-select
              class="select-primary tab"
              placeholder="Single Select"
              v-model="selects.simple_site"
              @change="callDataFromAPI()"
            >
              <el-option
                v-for="option in selects.site"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              ></el-option>
            </el-select>
          </h4>
        </div>
        <div class="col-lg-3" >
          <h4>
            Select time:
            <el-select
              class="select-primary tab"
              placeholder="Single Select"
              v-model="selects.simple_time"
              
              @change="callDataFromAPI()"
            >
              <el-option
                v-for="option in selects.time"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              ></el-option>
            </el-select>
          </h4>
        </div>
      </div>

      <!-- first row -->
      <div class="row" style="padding-top: 5px">
        <div class="col-lg-6">
          <div class="row">
            <!-- traffic count -->
            <div class="col-lg d-flex">
              <card type="card" class="flex-fill">
                <template slot="header">
                  <div
                    class="col-sm-12 title-color"
                    :class="isRTL ? 'text-right' : 'text-left'"
                  >
                    <h4 class="card-title">TRAFFIC COUNT</h4>
                    <p class="card-title">Kiraan Traffik</p>
                  </div>
                </template>
                <div class="content-area">
                  <img
                    src="@/assets/img/car3.png"
                    width="50%"
                    align="middle"
                    alt="Responsive image"
                  />
                  
                  <h3>{{ trafficCount }}
                    <i
                        v-if="odload"
                        class="fas fa-spinner fa-pulse"
                        style="color:white; font-size: 30px; "
                    ></i>
                  </h3>
                  <p>Total Count</p>
                </div>
              </card>
            </div>
            <!-- average speed -->
            <div class="col-lg d-flex">
              <card type="card" class="flex-fill">
                <template slot="header">
                  <div
                    class="col-sm-12 title-color"
                    :class="isRTL ? 'text-right' : 'text-left'"
                  >
                    <h4 class="card-title">AVERAGE SPEED</h4>
                    <p class="card-title">Purata Kelajuan</p>
                  </div>
                </template>
                <div class="content-area">
                  <vue-svg-gauge
                    :start-angle="-90"
                    :end-angle="90"
                    :value="averageSpeed"
                    :separator-step="20"
                    :min="0"
                    :max="240"
                    :gauge-color="[
                      { offset: 0, color: '#FAD01A' },
                      { offset: 100, color: '#8CDFAD' }
                    ]"
                    :scale-interval="10.0"
                  >
                  </vue-svg-gauge>
                  <h3>{{ averageSpeed }}
                      <i
                        v-if="odload"
                        class="fas fa-spinner fa-pulse"
                        style="color:white; font-size: 30px; "
                      ></i>
                  </h3>
                  <p>KM/H</p>
                </div>
              </card>
            </div>
          </div>

          <div class="row d-flex">
            <div class="col-lg d-flex">
              <card type="card" class="flex-fill">
                <template slot="header">
                  <div
                    class="col-sm-12 title-color"
                    :class="isRTL ? 'text-right' : 'text-left'"
                  >
                    <h4 class="card-title">TOTAL OVER SPEED LIMIT</h4>
                    <p class="card-title">Jumlah Melebihi Had Laju</p>
                  </div>
                </template>
                <div class="content-area">
                  <h3>{{ overSpeed }}
                    <i
                        v-if="odload"
                        class="fas fa-spinner fa-pulse"
                        style="color:white; font-size: 30px; "
                    ></i>
                  </h3>
                  <p>{{ overSpeedCent }}% from total detection</p>
                </div>
              </card>
            </div>
            <!-- under speed -->
            <div class="col-lg d-flex">
              <card type="card" class="flex-fill">
                <template slot="header">
                  <div
                    class="col-sm-12 title-color"
                    :class="isRTL ? 'text-right' : 'text-left'"
                  >
                    <h4 class="card-title">TOTAL BELOW SPEED LIMIT</h4>
                    <p class="card-title">Jumlah Dibawah Had Laju</p>
                  </div>
                </template>
                <div class="content-area">
                  <h3>{{ underSpeed }}
                    <i
                        v-if="odload"
                        class="fas fa-spinner fa-pulse"
                        style="color:white; font-size: 30px; "
                    ></i>
                  </h3>
                  <p>{{ underSpeedCent }}% from total detection</p>
                </div>
              </card>
            </div>

          </div>
        </div>

        <div class="col-lg-6">
          <div class="row">
            <!-- vehicle type graph -->
            <div class="col-lg-6">
              <card type="card-chart" class="">
                <template slot="header">
                  <div
                    class="col-sm title-color"
                    :class="isRTL ? 'text-right' : 'text-left'"
                  >
                    <h4 class="card-title">VEHICLE TYPE</h4>
                    <p class="card-title">Jenis Kenderaan</p>
                  </div>
                </template>
                <div class="content-area">
                  <pie-chart
                    v-if="piechart.loaded"
                    :chart-data="piechart.chartData"
                    :gradient-colors="piechart.gradientColors"
                    :gradient-stops="piechart.gradientStops"
                    :extra-options="piechart.extraOptions"
                    :height="200"
                  ></pie-chart>
                  <i
                    v-if="!piechart.loaded"
                    class="fas fa-spinner fa-pulse"
                    style="color:white; font-size: 30px; "
                  ></i>
                </div>
                
              </card>
            </div>
            <!-- speed percentage -->
            <div class="col-lg-6">
              <card type="card-chart" class="">
                <template slot="header">
                  <div
                    class="col-sm title-color"
                    :class="isRTL ? 'text-right' : 'text-left'"
                  >
                    <h4 class="card-title">SPEED PERCENTAGE</h4>
                    <p class="card-title">Purata Kelajuan</p>
                  </div>
                </template>
                <div class="content-area">
                  <pie-chart
                    v-if="piechart2.loaded"
                    :chart-data="piechart2.chartData"
                    :gradient-colors="piechart2.gradientColors"
                    :gradient-stops="piechart2.gradientStops"
                    :extra-options="piechart2.extraOptions"
                    :height="200"
                  ></pie-chart>
                  <i
                    v-if="!piechart2.loaded"
                    class="fas fa-spinner fa-pulse"
                    style="color:white; font-size: 30px; "
                  ></i>
                </div>
              </card>
            </div>
          </div>
          <div class="row">
            <!-- average speed graph -->
            <div class="col-lg">
              <card type="card-chart" class="">
                <template slot="header">
                  <div
                    class="col-sm-12 title-color"
                    :class="isRTL ? 'text-right' : 'text-left'"
                  >
                    <h4 class="card-title">
                      AVERAGE TRAFFIC COUNT / {{ this.selects.simple_label }}
                    </h4>
                    <p class="card-title">Purata Kiraan Trafik</p>
                  </div>
                </template>
                <div class="content-area">
                  <line-chart
                    v-if="greenLineChart1.loaded"
                    :chart-data="greenLineChart1.chartData"
                    :gradient-colors="greenLineChart.gradientColors"
                    :gradient-stops="greenLineChart.gradientStops"
                    :extra-options="greenLineChart.extraOptions"
                    :height="200"
                  ></line-chart>
                  <i
                    v-if="!greenLineChart1.loaded"
                    class="fas fa-spinner fa-pulse"
                    style="color:white; font-size: 30px; "
                  ></i>
                  
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal :show.sync="modal.site" type="large">
      <h2
        slot="header"
        class="modal-title text-center"
        id="modal-title-default"
      >Register Site</h2>
      <div class="col-lg">
        <base-input label="Site Name" type="success" placeholder="Site Name" v-model="sitename"></base-input>
      </div>
      <div style="text-align: center">
        <base-button type="primary">Submit</base-button>
        <base-button type="danger" sitename="">Cancel</base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import PieChart from "@/components/Charts/PieChart";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import Scatter from "@/components/Charts/ScatterChart";
import * as chartConfigs from "@/components/Charts/config";
import TaskList from "./TaskList";
import UserTable from "./UserTable";
import CountryMapCard from "./CountryMapCard";
import StatsCard from "src/components/Cards/StatsCard";
import config from "@/config";
import { Table, TableColumn } from "element-ui";
import moment from "moment-timezone";
import { VueSvgGauge } from "vue-svg-gauge";
import { Select, Option } from "element-ui";
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LControlSidebar,
  LTooltip
} from "vue2-leaflet";
import { Modal } from "src/components";
// import { constrainMarkerToRange } from 'fullcalendar/src/datelib/date-range';

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Scatter,
    LineChart,
    PieChart,
    BarChart,
    StatsCard,
    TaskList,
    CountryMapCard,
    UserTable,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    Modal
  },
  data() {
    return {
      // datacollection: null,
      // options: null,
      sitename: "",
      odload: true,
      selects: {
        site: [],
        time: [
          {
            value: "Hourly",
            label: "Hourly"
          },
          {
            value: "Daily",
            label: "Daily"
          },
          {
            value: "Weekly",
            label: "Weekly"
          },
          {
            value: "Monthly",
            label: "Monthly"
          }
        ],
        simple_time: "",
        simple_label: "",
        simple_site: ""
      },
      overSpeedCent: "",
      underSpeedCent: "",
      piechart: {
        extraOptions: chartConfigs.pieChartOptions,
        chartData: {
          labels: ["Motorcycle", "Private Vehicle", "Large Vehicle"],
          datasets: [
            {
              label: "Traffic Count",
              fill: true,
              borderColor: "#f4d03f",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              backgroundColor: ["#D19990", "#92dea5", "#90D1C9"],
              // pointBackgroundColor: "#f4d03f",
              // pointBorderColor: "rgba(255,255,255,0)",
              // pointHoverBackgroundColor: "#f4d03f",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [6, 5, 9]
            }
          ]
        },
        loaded: true
        // gradientColors: [
        //   'rgba(51,255,255,0.4)',
        //   'rgba(255,255,0,0.5)',
        //   'rgba(102,204,255,0.1)'
        // ],
        // gradientStops: [1, 0.1, 0]
      },
      piechart2: {
        extraOptions: chartConfigs.pieChartOptions,
        chartData: {
          labels: ["Compliance", "Not Compliance"],
          datasets: [
            {
              label: "Traffic Count",
              fill: true,
              borderColor: "#f4d03f",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              backgroundColor: ["#D1CD90", "#92dea5"],
              // pointBackgroundColor: "#f4d03f",
              // pointBorderColor: "rgba(255,255,255,0)",
              // pointHoverBackgroundColor: "#f4d03f",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [60, 40]
            }
          ]
        },
        loaded: true
        // gradientColors: [
        //   'rgba(51,255,255,0.4)',
        //   'rgba(255,255,0,0.5)',
        //   'rgba(102,204,255,0.1)'
        // ],
        // gradientStops: [1, 0.1, 0]
      },
      greenLineChart1: {
        extraOptions: chartConfigs.greenChartOptions,
        chartData: {
          labels: ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
          datasets: [
            {
              label: "Motorcycle",
              fill: true,
              borderColor: "#D19990",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#D19990",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#D19990",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0, 0, 0, 0, 0, 0]
            },
            {
              label: "Lorry",
              fill: true,
              borderColor: "#92dea5",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#92dea5",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#92dea5",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0, 0, 0, 0, 0, 0],
              type: 'line'
            },
            {
              label: "Car",
              fill: true,
              borderColor: "#D1CD90",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#D1CD90",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#D1CD90",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0, 0, 0, 0, 0, 0],
              type: 'line'
            }
          ]
        },
        loaded: false,
        gradientColors: [
          "rgba(51,255,255,0.4)",
          "rgba(255,255,0,0.5)",
          "rgba(102,204,255,0.1)"
        ],
        gradientStops: [1, 0.1, 0]
      },
      greenLineChart: {
        extraOptions: chartConfigs.greenChartOptions,
        type: 'line',
        chartData2: {
          labels: ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
          datasets: [
            {
              label: "Speed",
              fill: true,
              borderColor: "#f4d03f",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#f4d03f",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#f4d03f",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [0, 0, 0, 0, 0, 0]
            }
          ]
        },
        loaded: false,
        gradientColors: [
          "rgba(51,255,255,0.4)",
          "rgba(255,255,0,0.5)",
          "rgba(102,204,255,0.1)"
        ],
        gradientStops: [1, 0.1, 0]
      },
     
     
      date: "",
      time: "",
      averageSpeed: 0,
      currentSpeed: 0,
      overSpeed: 0,
      underSpeed: 0,
      trafficCount: 0,
      x: 0,
      y: 0,
      speed: "0KM/h",
      rcs: "None",
      tooltip: "",
      siteList: [],
      modal: {
        site: false
      }
    };
  },
  mounted() {
    // this.barChart(0)
    this.$mqtt.subscribe("jkrradar/#");
    // setInterval(this.callDataFromAPI, 7000);
  },
  computed: {
    barChartCategories() {
      return [
        { name: "Below", icon: "tim-icons icon-minimal-down" },
        { name: "Over", icon: "tim-icons icon-minimal-up" }
      ];
    },
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  created() {
    setInterval(() => {
      this.date = moment().format("DD MMMM Y");
      this.time = moment().format("h:mm:ss A");
    }, 1000);

    // list of site and default value(time and site)
    this.getSiteList();
  },
  methods: {
    getSite() {
      console.log("dattetetet");
      this.callDataFromAPI(this.selects.simple_site);
      this.getTimew(this.selects.simple_site);
    },
    getSiteList() {
        this.odload = true
      //  let url =
      //     "https://alpha.vectolabs.com:666/speed/" +
      //     start +
      //     "/" +
      //     end +
      //     "/" +
      //     this.selects.simple_time +
      //     "";

      // let url = "http://127.0.0.1:4000/loc";

      let url = "https://alpha.vectolabs.com:666/loc";
      // console.log(this.selects.simple_site);
      var self = this;
      fetch(url, {
        method: "GET"
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          console.log(data.location);
        
          this.selects.site = [ 
            {
                label: data.location[0].SiteName,
                value: "0001"
            },
            {
                label: data.location[1].SiteName,
                value: "0002"
            }
          ]
          console.log(this.selects.site)
          // assign default value
          console.log(this.selects.site)
          this.selects.simple_time = this.selects.time[0].value;
          this.selects.simple_label = this.selects.time[0].label;
          this.selects.simple_site = this.selects.site[0].value;

          console.log(this.selects.simple_time, this.selects.simple_site)

          // call data
          this.callDataFromAPI();
          
          });
    },
    getTimew() {
        
      console.log("MASUK GRAPh")
      this.greenLineChart1.loaded = false;

      var hoursPerDay = 24;
      let start = null;
      let end = null;
      if (this.selects.simple_time == "Hourly") {
        this.selects.simple_label = "Hourly";
        start = moment().unix();
        end = moment().unix();
      } else if (this.selects.simple_time == "Daily") {
        this.selects.simple_label = "Daily";
        start = moment()
          .startOf("week")
          .unix();
        end = moment().unix();
      } else if (this.selects.simple_time == "Weekly") {
        this.selects.simple_label = "Weekly";
        start = moment()
          .startOf("month")
          .unix();
        end = moment().unix();
      } else {
        this.selects.simple_label = "Monthly";
        start = moment()
          .startOf("year")
          .unix();
        end = moment().unix();
        console.log(start);
      }
      let url = "";

    //   local 
      // url =
      //     "http://127.0.0.1:4000/speed/" +
      //     start +
      //     "/" +
      //     end +
      //     "/" +
      //     this.selects.simple_time +
      //     "/" +
      //     this.selects.simple_site
      //     ;

    // cloud
        url =
          "https://alpha.vectolabs.com:666/speed/" +
          start +
          "/" +
          end +
          "/" +
          this.selects.simple_time +
          "/" +
          this.selects.simple_site
          ;
   

      var self = this;
      fetch(url, {
        method: "GET"
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          let timestamp = [];
          console.log("TEST: ", data);

          
          let lorry = []
          let kete = []
          let moto = []
          for(var i=0; i<data.kete.length; i++){
            
            
            kete.push(data.kete[i].value)
          }
          for(var i=0; i<data.lori.length; i++){
            lorry.push(data.lori[i].value)
          }
          for(var i=0; i<data.moto.length; i++){
            moto.push(data.moto[i].value)

            if (this.selects.simple_time == "Hourly") {
              timestamp.push(moment(data.moto[i].time).format("HH:mm:ss"))
            } else if (this.selects.simple_time == "Daily") {
              timestamp.push(moment(data.moto[i].time).format("YYYY-MM-DD"))
            } else if (this.selects.simple_time == "Weekly") {
              timestamp.push(moment(data.moto[i].time).format("YYYY-MM-DD"))
            } else {
              timestamp.push(moment(data.moto[i].time).format("MMM"))
            }
          }

          console.log(timestamp);
          console.log()

          //pieChart
          this.greenLineChart1 = {
            extraOptions: chartConfigs.greenChartOptions,
            type: 'line',
            chartData: {

              labels: timestamp,
              datasets: [
                {
                  label: "Motorcycle",
                  fill: true,
                  borderColor: "#D19990",
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  pointBackgroundColor: "#D19990",
                  pointBorderColor: "rgba(255,255,255,0)",
                  pointHoverBackgroundColor: "#D19990",
                  pointBorderWidth: 20,
                  pointHoverRadius: 4,
                  pointHoverBorderWidth: 15,
                  pointRadius: 4,
                  data: moto
                },
                {
                  label: "Lorry",
                  fill: true,
                  borderColor: "#92dea5",
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  pointBackgroundColor: "#92dea5",
                  pointBorderColor: "rgba(255,255,255,0)",
                  pointHoverBackgroundColor: "#92dea5",
                  pointBorderWidth: 20,
                  pointHoverRadius: 4,
                  pointHoverBorderWidth: 15,
                  pointRadius: 4,
                  data: lorry,
                  type: 'line'
                },
                {
                  label: "Car",
                  fill: true,
                  borderColor: "#D1CD90",
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  pointBackgroundColor: "#D1CD90",
                  pointBorderColor: "rgba(255,255,255,0)",
                  pointHoverBackgroundColor: "#D1CD90",
                  pointBorderWidth: 20,
                  pointHoverRadius: 4,
                  pointHoverBorderWidth: 15,
                  pointRadius: 4,
                  data: kete,
                  type: 'line'
                }
              ]
            },
            loaded: true,
            gradientColors: [
              "rgba(51,255,255,0.4)",
              "rgba(255,255,0,0.5)",
              "rgba(102,204,255,0.1)"
            ],
            gradientStops: [1, 0.1, 0]
          };

          this.greenLineChart1.loaded = true;
        });
    },
    // DataPoint(speed, rcs) {
    //   // this.x = x;
    //   // this.y = y;
    //   // this.speed = speed;
    //   // this.rcs = rcs
    //   this.tooltip = "speed: " + speed + ", rcs: " + rcs + ", location";
    //   // console.log(this.tooltip)
    //   return this.tooltip;
    // },
    // passAverageTrafficData(y, x) {
    //   let greenLineChart = {
    //     extraOptions: chartConfigs.greenChartOptions,
    //     chartData: {
    //       labels: x,
    //       datasets: [
    //         {
    //           label: "Traffic Count",
    //           fill: true,
    //           borderColor: "#f4d03f",
    //           borderWidth: 2,
    //           borderDash: [],
    //           borderDashOffset: 0.0,
    //           pointBackgroundColor: "#f4d03f",
    //           pointBorderColor: "rgba(255,255,255,0)",
    //           pointHoverBackgroundColor: "#f4d03f",
    //           pointBorderWidth: 20,
    //           pointHoverRadius: 4,
    //           pointHoverBorderWidth: 15,
    //           pointRadius: 4,
    //           data: y
    //         }
    //       ]
    //     },
    //     gradientColors: [
    //       "rgba(51,255,255,0.4)",
    //       "rgba(255,255,0,0.5)",
    //       "rgba(102,204,255,0.1)"
    //     ],
    //     gradientStops: [1, 0.1, 0]
    //   };
    //   this.greenLineChart = greenLineChart;
    // },
    // passAverageSpeedData(y, x) {
    //   let greenLineChart = {
    //     extraOptions: chartConfigs.greenChartOptions,
    //     chartData2: {
    //       labels: x,
    //       datasets: [
    //         {
    //           label: "Speed",
    //           fill: true,
    //           borderColor: "#f4d03f",
    //           borderWidth: 2,
    //           borderDash: [],
    //           borderDashOffset: 0.0,
    //           pointBackgroundColor: "#f4d03f",
    //           pointBorderColor: "rgba(255,255,255,0)",
    //           pointHoverBackgroundColor: "#f4d03f",
    //           pointBorderWidth: 20,
    //           pointHoverRadius: 4,
    //           pointHoverBorderWidth: 15,
    //           pointRadius: 4,
    //           data: y
    //         }
    //       ]
    //     },
    //     gradientColors: [
    //       "rgba(51,255,255,0.4)",
    //       "rgba(255,255,0,0.5)",
    //       "rgba(102,204,255,0.1)"
    //     ],
    //     gradientStops: [1, 0.1, 0]
    //   };
    //   this.greenLineChart = greenLineChart;
    // },
    // getAverageTrafficCount() {
    //   var self = this;
    //   var averageTrafficLabel = [];
    //   var averageTrafficData = [];

    //   fetch("https://staging.vectolabs.com:1010/traffic/average", {
    //     method: "GET"
    //   })
    //     .then(response => {
    //       return response.json();
    //     })
    //     .then(data => {
    //       console.log(data.data);
    //       console.log(data.data.length);
    //       // console.log(data.updatedat)
    //       for (var i = 0; i < data.data.length; i++) {
    //         console.log(data.data[i].total);
    //         averageTrafficData.push(parseInt(data.data[i].total));
    //         console.log(data.data[i].total);
    //         let sdate = data.data[i].createdat;
    //         sdate = sdate.replace(/\T/g, " ");
    //         sdate = sdate.replace(/\Z/g, " ");
    //         // averageTrafficLabel.push(moment().tz(data.data[i].createdat,"MMM DD YYYY HH:MM:SS","Asia/Kuala_Lumpur").format())
    //         averageTrafficLabel.push(sdate);
    //       }
    //       this.passAverageTrafficData(averageTrafficData, averageTrafficLabel);
    //     });
    // },
    // getAverageSpeed() {
    //   var self = this;
    //   var averageSpeedLabel = [];
    //   var averageSpeedData = [];

    //   fetch("https://staging.vectolabs.com:1010/speed/average", {
    //     method: "GET"
    //   })
    //     .then(response => {
    //       return response.json();
    //     })
    //     .then(data => {
    //       console.log(data.data);
    //       for (var i = 0; i < data.data.length; i++) {
    //         console.log(data.data[i].createdat);
    //         averageSpeedData.push(parseInt(data.data[i].value));
    //         let sdate = data.data[i].createdat;
    //         sdate = sdate.replace(/\T/g, " ");
    //         sdate = sdate.replace(/\Z/g, " ");
    //         // averageSpeedLabel.push(moment(data.data[i].createdat).format('MMM DD YYYY HH:MM:SS'))
    //         averageSpeedLabel.push(sdate);
    //       }
    //       this.passAverageSpeedData(averageSpeedData, averageSpeedLabel);
    //     });
    // },
    callDataFromAPI() {
      console.log("masuk")
      console.log(this.selects.simple_site)
      console.log(this.selects.simple_time)

      let start = ""
      let end = ""
      if (this.selects.simple_time == "Hourly"){
        start = moment().format("YYYY-MM-DD")
        end = moment().format("YYYY-MM-DD")
      } else if (this.selects.simple_time == "Daily"){
        start = moment().startOf('week').format("YYYY-MM-DD")
        end = moment().endOf('week').format("YYYY-MM-DD")
      } else if (this.selects.simple_time == "Weekly"){
        start = moment().startOf('month').format("YYYY-MM-DD")
        end = moment().endOf('month').format("YYYY-MM-DD")
      } else {
        start = moment().startOf('year').format("YYYY-MM-DD")
        end = moment().endOf('year').format("YYYY-MM-DD")
      }

      console.log(start, end)
      
      let url = "https://alpha.vectolabs.com:666/counter/latest/" + this.selects.simple_site + "/" + start + "/" + end;

      // let url = "http://127.0.0.1:4000/counter/latest/" + this.selects.simple_site + "/" + start + "/" + end;
      
      var self = this;
      fetch(url, {
        method: "GET"
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          console.log("LATEST: ", data);
          let typedata = [
            parseInt(data.moto),
            parseInt(data.kete),
            parseInt(data.lori)
          ]
          let piedata = [
            parseInt(data.lowspeed),
            parseInt(data.over5),
            parseInt(data.over10),
            parseInt(data.over15),
            parseInt(data.over20),
            parseInt(data.over25),
            parseInt(data.over30)
          ];

          this.currentSpeed = data.current;
          this.averageSpeed = parseInt(data.average);
          this.trafficCount = data.total;
          this.overSpeedCent = ((data.highspeed / data.total) * 100).toFixed(1);
          this.overSpeed = data.highspeed;
          console.log(this.trafficCount, this.overSpeed)
          
          
          this.underSpeed = data.total - data.highspeed;
          console.log(this.underSpeed)
          this.underSpeedCent = (((data.total - data.highspeed) / data.total) * 100).toFixed(1);

          console.log(this.underSpeed)
          if(this.underSpeed == ""){
              this.underSpeed = 0
          }
          //piechart

          this.underspeed = {
            extraOptions: chartConfigs.pieChartOptions,
            chartData: {
              labels: [
                "Underspeed",
                "Over 5",
                "Over 10",
                "Over 15",
                "Over 20",
                "Over 25",
                "Over 30"
              ],
              datasets: [
                {
                  label: "Traffic Count",
                  fill: true,
                  borderColor: "#f4d03f",
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  backgroundColor: [
                    "#D19990",
                    "#92dea5",
                    "#90D1C9",
                    "#D190CF",
                    "#D1CE90",
                    "#D1A990",
                    "#90AED1"
                  ],
                  // pointBackgroundColor: "#f4d03f",
                  // pointBorderColor: "rgba(255,255,255,0)",
                  // pointHoverBackgroundColor: "#f4d03f",
                  pointBorderWidth: 20,
                  pointHoverRadius: 4,
                  pointHoverBorderWidth: 15,
                  pointRadius: 4,
                  data: piedata
                }
              ]
            },
            loaded: true
          };
          this.piechart = {
            extraOptions: chartConfigs.pieChartOptions,
            chartData: {
              labels: ["Motorcycle", "Private Vehicle", "Large Vehicle"],
              datasets: [
                {
                  label: "Traffic Count",
                  fill: true,
                  borderColor: "#f4d03f",
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  backgroundColor: ["#D19990", "#92dea5", "#90D1C9"],
                  // pointBackgroundColor: "#f4d03f",
                  // pointBorderColor: "rgba(255,255,255,0)",
                  // pointHoverBackgroundColor: "#f4d03f",
                  pointBorderWidth: 20,
                  pointHoverRadius: 4,
                  pointHoverBorderWidth: 15,
                  pointRadius: 4,
                  data: typedata
                }
              ]
            },
            loaded: true
            // gradientColors: [
            //   'rgba(51,255,255,0.4)',
            //   'rgba(255,255,0,0.5)',
            //   'rgba(102,204,255,0.1)'
            // ],
            // gradientStops: [1, 0.1, 0]
          };
          //pieChart
          this.piechart2 = {
            extraOptions: chartConfigs.pieChartOptions,
            chartData: {
              labels: ["Overspeed", "Underspeed"],
              datasets: [
                {
                  label: "Traffic Count",
                  fill: true,
                  borderColor: "#f4d03f",
                  borderWidth: 2,
                  borderDash: [],
                  borderDashOffset: 0.0,
                  backgroundColor: ["#D1CD90", "#92dea5"],
                  // pointBackgroundColor: "#f4d03f",
                  // pointBorderColor: "rgba(255,255,255,0)",
                  // pointHoverBackgroundColor: "#f4d03f",
                  pointBorderWidth: 20,
                  pointHoverRadius: 4,
                  pointHoverBorderWidth: 15,
                  pointRadius: 4,
                  data: [this.overSpeedCent, this.underSpeedCent]
                }
              ]
            },
            loaded: true
            // gradientColors: [
            //   'rgba(51,255,255,0.4)',
            //   'rgba(255,255,0,0.5)',
            //   'rgba(102,204,255,0.1)'
            // ],
            // gradientStops: [1, 0.1, 0]
          };
        });
        this.odload = false
        this.getTimew();
    }
  },
  mqtt: {
    "jkrradar/data"(data) {
      console.log(data);
      let dataJKR = String.fromCharCode.apply(null, data);
      let sensordata = JSON.parse(dataJKR);
      console.log(sensordata);
      this.currentSpeed = sensordata.currspeed;
      this.averageSpeed = sensordata.avgspeed;
      this.overSpeed = sensordata.overspeed;
      this.underSpeed = sensordata.belowspeed;
      this.trafficCount = sensordata.counter;
      console.log(parseInt(this.currentSpeed));
    },
    "jkrradar/reset"(data) {
      console.log(data);

      this.currentSpeed = "0";
      this.averageSpeed = "0";
      this.overSpeed = "0";
      this.underSpeed = "0";
      this.trafficCount = "0";
    }
  }
};
</script>
<style>
.content-area h3 {
  color: #f4d03f;
  /* padding-top: 25%; */
}
.content-area p {
  /* padding-top: 10%; */
}
.card-title {
  text-align: right;
}
.card-style {
  height: 45%;
}
.header .title {
  display: inline;
}
.title-color h4 {
  /* color: ; */
}
.content-area {
  text-align: center;
}
.scatter {
}
.line {
  border: 1px solid #ffffff;
  height: 645px;
  transform: rotate(60deg);
  position: absolute;
  right: 210px;
  top: 0px;
  z-index: 1;
}
.line2 {
  border: 1px solid #ffffff;
  height: 645px;
  transform: rotate(120deg);
  position: absolute;
  left: 159px;
  top: 0px;
  z-index: 1;
}
</style>
