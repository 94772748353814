<template>
  <div>
    <h2 class="text-center">Data Analytics</h2>
    <div class="col-md-12">
      <card class="card-chart" no-footer-line>
        <template slot="header">
          <h3 class="card-title">
            <i class="tim-icons icon-chart-bar-32 text-primary"></i> All Data (Hourly)
          </h3>
        </template>
        <div class="card-chart">
          <line-chart
            :chart-data="combineLineGraph.chartData"
            :gradient-colors="combineLineGraph.gradientColors"
            :gradient-stops="combineLineGraph.gradientStops"
            :extra-options="combineLineGraph.extraOptions"
            :height="130"
          ></line-chart>
        </div>
      </card>
    </div>
    <div class="col-md-12">
      <card class="card-chart" no-footer-line>
        <template slot="header">
          <h3 class="card-title">
            <i class="tim-icons icon-chart-bar-32 text-primary"></i> Counter (Daily)
          </h3>
        </template>
        <div class="chart">
          <line-chart
            :chart-data="lineChart2.chartData"
            :gradient-colors="lineChart2.gradientColors"
            :gradient-stops="lineChart2.gradientStops"
            :extra-options="lineChart2.extraOptions"
            :height="200"
          ></line-chart>
        </div>
      </card>
    </div>

    <div class="col-md-12">
      <card class="card-chart" no-footer-line>
        <template slot="header">
          <h3 class="card-title">
            <i class="tim-icons icon-chart-bar-32 text-primary"></i> Odour (Hourly)
          </h3>
        </template>
        <div class="card-chart">
          <line-chart
            :chart-data="lineChart1.chartData"
            :gradient-colors="lineChart1.gradientColors"
            :gradient-stops="lineChart1.gradientStops"
            :extra-options="lineChart1.extraOptions"
            :height="100"
          ></line-chart>
        </div>
      </card>
    </div>

    <div class="col-md-12">
      <card class="card-chart" no-footer-line>
        <template slot="header">
          <h3 class="card-title">
            <i class="tim-icons icon-chart-bar-32 text-primary"></i> Temperature (Hourly)
          </h3>
        </template>
        <div class="card-chart">
          <line-chart
            :chart-data="lineChart3.chartData"
            :gradient-colors="lineChart3.gradientColors"
            :gradient-stops="lineChart3.gradientStops"
            :extra-options="lineChart3.extraOptions"
            :height="100"
          ></line-chart>
        </div>
      </card>
    </div>
    <div class="col-md-12">
      <card class="card-chart" no-footer-line>
        <template slot="header">
          <h3 class="card-title">
            <i class="tim-icons icon-chart-bar-32 text-primary"></i> Humidity (Hourly)
          </h3>
        </template>
        <div class="card-chart">
          <line-chart
            :chart-data="lineChart4.chartData"
            :gradient-colors="lineChart4.gradientColors"
            :gradient-stops="lineChart4.gradientStops"
            :extra-options="lineChart4.extraOptions"
            :height="100"
          ></line-chart>
        </div>
      </card>
    </div>

    <div class="col-md-12">
      <card class="card-chart" no-footer-line>
        <template slot="header">
          <h3 class="card-title">
            <i class="tim-icons icon-chart-bar-32 text-primary"></i> Sound (Hourly)
          </h3>
        </template>
        <div class="card-chart">
          <line-chart
            :chart-data="lineChart5.chartData"
            :gradient-colors="lineChart5.gradientColors"
            :gradient-stops="lineChart5.gradientStops"
            :extra-options="lineChart5.extraOptions"
            :height="100"
          ></line-chart>
        </div>
      </card>
    </div>
    <div class="col-lg-6 d-flex">
      <card type="card-chart" class="card-style">
        <template slot="header">
          <div class="col-sm-12">
            <h2 class="card-title">TOTAL COMPLAINT SINCE (12/12/2019)</h2>
            <p class="card-title">Jumlah Aduan dari (12/12/2019)</p>
          </div>
        </template>
        <div class="card-chart">
          <pie-chart
            style="height:100%"
            :chart-data="pieChart2.chartData"
            :extra-options="pieChart2.extraOptions"
          ></pie-chart>
        </div>
      </card>
    </div>

    <div class="col-md-12">
      <card class="card-chart" no-footer-line>
        <template slot="header">
          <h3 class="card-title">
            <i class="tim-icons icon-chart-bar-32 text-primary"></i>
            <b>Counter Vs Odour</b>
          </h3>
        </template>
        <div class="chart">
          <bar-chart :chart-data="barChart2.chartData" :height="200"></bar-chart>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import LineChart from "src/components/Charts/LineChart";
import BarChart from "src/components/Charts/BarChart";
import PieChart from "src/components/Charts/PieChart";
import config from "@/config";
import * as chartConfigs from "@/components/Charts/config";
import moment from "moment";

export default {
  components: {
    LineChart,
    BarChart,
    PieChart
  },
  data() {
    return {
      pieChart2: {
        chartData: {
          datasets: [
            {
              label: "Total Complaint",
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: [
                "#5D3FB6",
                "#3F9BB1",
                "#8EB542",
                "#FAD01A",
                "#F57C21",
                "#EF4528",
                "#CB0B0B",
                "#A9336B"
              ],
              borderWidth: 0,
              data: []
            }
          ]
        },
        extraOptions: chartConfigs.pieChartOptions
      },
      lineChart1: {
        chartData: {
          labels: ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
          datasets: [
            {
              label: "Data",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#be55ed",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [80, 100, 70, 80, 120, 80]
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      lineChart2: {
        chartData: {
          labels: ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
          datasets: [
            {
              label: "Data",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#be55ed",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [80, 100, 70, 80, 120, 80]
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      lineChart3: {
        chartData: {
          labels: ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
          datasets: [
            {
              label: "Data",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#be55ed",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [80, 100, 70, 80, 120, 80]
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      lineChart4: {
        chartData: {
          labels: ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
          datasets: [
            {
              label: "Data",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#be55ed",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [80, 100, 70, 80, 120, 80]
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      lineChart5: {
        chartData: {
          labels: ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
          datasets: [
            {
              label: "Data",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#be55ed",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [80, 100, 70, 80, 120, 80]
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      combineLineGraph: {
        chartData: {
          labels: ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
          datasets: [
            {
              label: "Data",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: config.colors.primary,
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#be55ed",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              data: [80, 100, 70, 80, 120, 80]
            }
          ]
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      },
      barChart2: {
        chartData: {
          labels: [
            "00 - 01",
            "01 - 02",
            "02 - 03",
            "03 - 04",
            "04 - 05",
            "05 - 06",
            "06 - 07",
            "07 - 08",
            "08 - 09",
            "09 - 10",
            "10 - 11",
            "11 - 12",
            "12 - 13",
            "13 - 14",
            "14 - 15",
            "15 - 16",
            "16 - 17",
            "17 - 18",
            "18 - 19",
            "19 - 20",
            "20 - 21",
            "21 - 22",
            "22 - 23",
            "23 - 00"
          ],
          datasets: [
            {
              label: "Monday",
              fill: false,
              hoverBackgroundColor: config.colors.orange,
              borderColor: config.colors.orange,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data: [
                80,
                100,
                70,
                80,
                120,
                80,
                80,
                100,
                70,
                80,
                120,
                80,
                80,
                100,
                70,
                80,
                120,
                80,
                80,
                20,
                30,
                40,
                35,
                75
              ]
            }
          ]
        }
      }
    };
  },
  methods: {
    callSensorData: function() {
      var self = this;
      let today = moment()
        .utc()
        .unix();
      // let last7Day = moment("12-13").utc();
      let last7Day = moment()
        .utc()
        .startOf("day")
        .subtract(11, "days")
        .unix();
      return fetch(
        "https://staging.vectolabs.com/sensor/" + last7Day + "/" + today,
        {
          method: "GET"
        }
      )
        .then(response => {
          // eslint-disable-next-line no-console
          return response.json();
        })
        .then(data => {
          this.initSensorLine(data.data, "odour");
          this.initSensorLine(data.data, "humidity");
          this.initSensorLine(data.data, "sound");
          this.initSensorLine(data.data, "temp");
          return data.data;
        });
    },
    initSensorLine(data, sensor) {
      // Array for label
      let timeStamp = [];
      let dayOf = [];

      // for (let i = 0; i < data.length; i++) {
      //   timeStamp.push(
      //     moment(data[i].createdat)
      //       .utc()
      //       .format("MMM Do YY")
      //   );
      //   dayOf.push(moment(data[i].createdat).format("D"));
      // }
      // let _dayOf = [...new Set(dayOf)];

      // // Declaring arrays of total days
      // for (let i = 0; i < _dayOf.length; i++) {
      //   window["_" + i] = new Array();
      // }

      // Storing sensor data
      // if (sensor == "odour") {
      //   for (let i = 0; i < data.length; i++) {
      //     for (let j = 0; j < _dayOf.length; j++) {
      //       let day = moment(data[i].createdat)
      //         .utc()
      //         .format("D");
      //       let parsedValue = parseInt(data[i].voc);
      //       if (day == _dayOf[j]) {
      //         window["_" + j].push(parsedValue);
      //       }
      //     }
      //   }
      // } else if (sensor == "temp") {
      //   for (let i = 0; i < data.length; i++) {
      //     for (let j = 0; j < _dayOf.length; j++) {
      //       let day = moment(data[i].createdat)
      //         .utc()
      //         .format("D");
      //       let parsedValue = parseInt(data[i].temp);
      //       if (day == _dayOf[j]) {
      //         window["_" + j].push(parsedValue);
      //       }
      //     }
      //   }
      // } else if (sensor == "humidity") {
      //   for (let i = 0; i < data.length; i++) {
      //     for (let j = 0; j < _dayOf.length; j++) {
      //       let day = moment(data[i].createdat)
      //         .utc()
      //         .format("D");
      //       let parsedValue = parseInt(data[i].humidity);
      //       if (day == _dayOf[j]) {
      //         window["_" + j].push(parsedValue);
      //       }
      //     }
      //   }
      // } else if (sensor == "sound") {
      //   for (let i = 0; i < data.length; i++) {
      //     for (let j = 0; j < _dayOf.length; j++) {
      //       let day = moment(data[i].createdat)
      //         .utc()
      //         .format("D");
      //       let parsedValue = parseInt(data[i].sound);
      //       if (day == _dayOf[j]) {
      //         window["_" + j].push(parsedValue);
      //       }
      //     }
      //   }
      // }

      // let label = [...new Set(timeStamp)];

      // let odour = [];
      // for (let i = 0; i < _dayOf.length; i++) {
      //   odour.push(
      //     (window["_" + i].reduce((a, b) => a + b, 0) / 24).toFixed(2)
      //   );
      // }

      let label = [];
      let _data = [];

      for (let i = 0; i < data.length; i++) {
        if (sensor == "odour") {
          _data.push(data[i].voc);
        } else if (sensor == "temp") {
          _data.push(data[i].temp);
        } else if (sensor == "humidity") {
          _data.push(data[i].humidity);
        } else if (sensor == "sound") {
          _data.push(data[i].sound);
        }
      }

      for (let i = 0; i < data.length; i++) {
        label.push(
          moment(data[i].createdat)
            .utc()
            .format("MMM Do hh:mm a")
        );
      }

      let legend = "";

      switch (sensor) {
        case "odour":
          legend = "Odour";
          break;
        case "temp":
          legend = "Temperature";
          break;
        case "humidity":
          legend = "Humidity";
          break;
        case "sound":
          legend = "Sound";
          break;
        default:
          break;
      }
      let newData = {
        labels: label,
        datasets: [
          {
            label: legend,
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#be55ed",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: _data
          }
        ]
      };

      if (sensor == "odour") {
        this.lineChart1.chartData = newData;
      } else if (sensor == "temp") {
        this.lineChart3.chartData = newData;
      } else if (sensor == "humidity") {
        this.lineChart4.chartData = newData;
      } else if (sensor == "sound") {
        this.lineChart5.chartData = newData;
      }
    },
    // initSensorLine(data, sensor) {
    //   // Array for label
    //   let timeStamp = [];
    //   let dayOf = [];

    //   for (let i = 0; i < data.length; i++) {
    //     let day = moment(data[i].createdat).format("dddd");
    //     if (day == "Monday") {
    //       let first = moment(data[i].createdat).format("HH") + " Hour";
    //       timeStamp.push(first);
    //     }
    //   }

    //   for (let i = 0; i < data.length; i++) {
    //     let day = moment(data[i].createdat).format("dddd");

    //   }
    //   console.log(timeStamp)

    // for (let i = 0; i < data.length; i++) {
    //   timeStamp.push(moment(data[i].createdat).utc().format("MMM Do YY"));
    //   dayOf.push(moment(data[i].createdat).format("D"));
    // }
    // let _dayOf = [...new Set(dayOf)];

    // // Declaring arrays of total days
    // for (let i = 0; i < _dayOf.length; i++) {
    //   window["_" + i] = new Array();
    // }

    // // Storing sensor data
    // if (sensor == "odour") {
    //   for (let i = 0; i < data.length; i++) {
    //     for (let j = 0; j < _dayOf.length; j++) {
    //       let day = moment(data[i].createdat)
    //         .utc()
    //         .format("D");
    //       let parsedValue = parseInt(data[i].voc);
    //       if (day == _dayOf[j]) {
    //         window["_" + j].push(parsedValue);
    //       }
    //     }
    //   }
    // } else if (sensor == "temp") {
    //   for (let i = 0; i < data.length; i++) {
    //     for (let j = 0; j < _dayOf.length; j++) {
    //       let day = moment(data[i].createdat)
    //         .utc()
    //         .format("D");
    //       let parsedValue = parseInt(data[i].temp);
    //       if (day == _dayOf[j]) {
    //         window["_" + j].push(parsedValue);
    //       }
    //     }
    //   }
    // }

    // let label = [...new Set(timeStamp)];

    // let odour = [];
    // for (let i = 0; i < _dayOf.length; i++) {
    //   odour.push(
    //     (window["_" + i].reduce((a, b) => a + b, 0) / 24).toFixed(2)
    //   );
    // }

    // let newData = {
    //   labels: label,
    //   datasets: [
    //     {
    //       label: "Data",
    //       fill: true,
    //       borderColor: config.colors.primary,
    //       borderWidth: 2,
    //       borderDash: [],
    //       borderDashOffset: 0.0,
    //       pointBackgroundColor: config.colors.primary,
    //       pointBorderColor: "rgba(255,255,255,0)",
    //       pointHoverBackgroundColor: "#be55ed",
    //       pointBorderWidth: 20,
    //       pointHoverRadius: 4,
    //       pointHoverBorderWidth: 15,
    //       pointRadius: 4,
    //       data: odour
    //     }
    //   ]
    // };

    // if (sensor == "odour") {
    //   this.lineChart1.chartData = newData;
    // } else if (sensor == "temp") {
    //   this.lineChart1.chartData = newData;
    // }
    // },
    callCounterData: function() {
      var self = this;
      let today = moment()
        .utc()
        .unix();
      // let last7Day = moment("12-13").utc();
      let last7Day = moment()
        .utc()
        .startOf("day")
        .subtract(11, "days")
        .unix();
      return fetch(
        "https://staging.vectolabs.com/counter/" + last7Day + "/" + today,
        {
          method: "GET"
        }
      )
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.initCounterLine(data.data);
          let _sensorData = this.callSensorData();
          _sensorData.then(res => {
            this.initNewBarChart(data.data, res);
            this.initCombineGraph(data.data, res);
          });
          return data.data;
        });
    },
    initNewBarChart(data, sensorData) {
      let monday = [];
      let tuesday = [];
      let wednesday = [];
      let thursday = [];
      let friday = [];
      let saturday = [];
      let sunday = [];
      let timeStamp = [];

      let tempSensor = [];
      let tempTime = [];

      // Creating 24Hours Array
      for (var i = 1; i <= 12; i++) {
        window["_" + i + "AM"] = new Array();
      }
      for (var i = 1; i <= 12; i++) {
        window["_" + i + "PM"] = new Array();
      }

      for (let i = 0; i < sensorData.length; i++) {
        let time = moment(sensorData[i].createdat).format("HH");
        let parsedValue = parseInt(sensorData[i].voc);
        tempTime.push(moment(sensorData[i].createdat).format("HH"));
        switch (time) {
          case "01":
            _1AM.push(parsedValue);
            break;
          case "02":
            _2AM.push(parsedValue);
            break;
          case "03":
            _3AM.push(parsedValue);
            break;
          case "04":
            _4AM.push(parsedValue);
            break;
          case "05":
            _5AM.push(parsedValue);
            break;
          case "06":
            _6AM.push(parsedValue);
            break;
          case "07":
            _7AM.push(parsedValue);
            break;
          case "08":
            _8AM.push(parsedValue);
            break;
          case "09":
            _9AM.push(parsedValue);
            break;
          case "10":
            _10AM.push(parsedValue);
            break;
          case "11":
            _11AM.push(parsedValue);
            break;
          case "12":
            _12AM.push(parsedValue);
            break;
          case "13":
            _1PM.push(parsedValue);
            break;
          case "14":
            _2PM.push(parsedValue);
            break;
          case "15":
            _3PM.push(parsedValue);
            break;
          case "16":
            _4PM.push(parsedValue);
            break;
          case "17":
            _5PM.push(parsedValue);
            break;
          case "18":
            _6PM.push(parsedValue);
            break;
          case "19":
            _7PM.push(parsedValue);
            break;
          case "20":
            _8PM.push(parsedValue);
            break;
          case "21":
            _9PM.push(parsedValue);
            break;
          case "22":
            _10PM.push(parsedValue);
            break;
          case "23":
            _11PM.push(parsedValue);
            break;
          case "24":
            _12PM.push(parsedValue);
            break;
          default:
            break;
        }
      }
      let odour = [];
      for (var i = 1; i <= 12; i++) {
        odour.push(
          (window["_" + i + "AM"].reduce((a, b) => a + b, 0) / 7).toFixed(2)
        );
      }
      for (var i = 1; i <= 12; i++) {
        odour.push(
          (window["_" + i + "PM"].reduce((a, b) => a + b, 0) / 7).toFixed(2)
        );
      }
      odour.pop();
      let temp = [];

      // Storing counter data for each day of the week and timestamp
      for (let i = 0; i < data.length; i++) {
        let day = moment(data[i].createdat)
          .utc()
          .format("dddd");
        let time = moment(data[i].createdat).format("HH");
        if (day == "Monday") {
          monday.push(data[i].in);
        } else if (day == "Tuesday") {
          tuesday.push(data[i].in);
        } else if (day == "Wednesday") {
          wednesday.push(data[i].in);
        } else if (day == "Thursday") {
          thursday.push(data[i].in);
        } else if (day == "Friday") {
          friday.push(data[i].in);
        } else if (day == "Saturday") {
          saturday.push(data[i].in);
        } else if (day == "Sunday") {
          sunday.push(data[i].in);
        }
      }

      monday.shift();
      tuesday.shift();
      wednesday.shift();
      thursday.shift();
      friday.shift();
      saturday.shift();
      sunday.shift();

      let label = [];

      for (let i = 0; i < 23; i++) {
        let first = i.toString();
        let second = (i + 1).toString();
        first = first.length < 2 ? "0" + first : first;
        second = second.length < 2 ? "0" + second : second;
        let combine = first + " - " + second;
        label.push(combine);
      }

      let newData = {
        labels: label,
        datasets: [
          {
            label: "Monday",
            fill: true,
            borderColor: config.colors.orange,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: monday
          },
          {
            label: "Tuesday",
            fill: true,
            hoverBackgroundColor: config.colors.info,
            borderColor: config.colors.info,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: tuesday
          },
          {
            label: "Wednesday",
            fill: true,
            hoverBackgroundColor: config.colors.primary,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: wednesday
          },
          {
            label: "Thursday",
            fill: true,
            hoverBackgroundColor: config.colors.red,
            borderColor: config.colors.red,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: thursday
          },
          {
            label: "Friday",
            fill: true,
            hoverBackgroundColor: config.colors.green,
            borderColor: config.colors.green,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: friday
          },
          {
            label: "Saturday",
            fill: true,
            hoverBackgroundColor: config.colors.purple,
            borderColor: config.colors.purple,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: saturday
          },
          {
            label: "Sunday",
            fill: true,
            hoverBackgroundColor: config.colors.choc,
            borderColor: config.colors.choc,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: sunday
          },
          {
            type: "line",
            label: "Odour",
            fill: false,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#be55ed",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: odour
          }
        ]
      };
      this.barChart2.chartData = newData;
    },
    callFormData: function() {
      var self = this;
      let today = moment()
        .utc()
        .unix();
      let last7Day = moment()
        .utc()
        .startOf("day")
        .subtract(6, "days")
        .unix();

      fetch("https://staging.vectolabs.com/form", {
        method: "GET"
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.initPie(data.data);
        });
    },
    initPie(data) {
      let complaints = [];
      complaints.push(data.busuk);
      complaints.push(data.lantai_basah);
      complaints.push(data.paip_bocor);
      complaints.push(data.sabun_habis);
      complaints.push(data.sampah_penuh);
      complaints.push(data.sani);
      complaints.push(data.tandas_sumbat);
      complaints.push(data.tisu_habis);
      let chartData = {
        datasets: [
          {
            label: "Total Complaint",
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: [
              "#5D3FB6",
              "#3F9BB1",
              "#8EB542",
              "#FAD01A",
              "#F57C21",
              "#EF4528",
              "#CB0B0B",
              "#A9336B"
            ],

            data: complaints
          }
        ],

        labels: [
          "Busuk",
          "Lantai Licin / Kotor",
          "Paip Bocor",
          "Sabun Habis",
          "Tong Sampah Penuh",
          "Sanitari Bin Penuh",
          "Tandas Sumbat",
          "Tisu habis"
        ]
      };

      this.pieChart2.chartData = chartData;
    },
    initCounterLine(data) {
      // Array for label
      let timeStamp = [];
      let dayOf = [];

      for (let i = 0; i < data.length; i++) {
        timeStamp.push(
          moment(data[i].createdat)
            .utc()
            .format("MMM Do YY")
        );
        dayOf.push(moment(data[i].createdat).format("D"));
      }
      let _dayOf = [...new Set(dayOf)];

      // Declaring arrays of total days
      for (let i = 0; i < _dayOf.length; i++) {
        window["_" + i] = new Array();
      }

      // Storing sensor data
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < _dayOf.length; j++) {
          let day = moment(data[i].createdat)
            .utc()
            .format("D");
          let parsedValue = parseInt(data[i].in);
          if (day == _dayOf[j]) {
            window["_" + j].push(parsedValue);
          }
        }
      }

      let label = [...new Set(timeStamp)];

      let counter = [];
      for (let i = 0; i < _dayOf.length; i++) {
        counter.push(window["_" + i].reduce((a, b) => a + b, 0));
      }

      let newData = {
        labels: label,
        datasets: [
          {
            label: "Data",
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#be55ed",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: counter
          }
        ]
      };

      this.lineChart2.chartData = newData;
    },
    initCombineGraph(counterData, sensorData) {
      let label = [];
      let counter = [];
      let odour = [];
      let temperature = [];
      let humidity = [];
      let sound = [];

      console.log(counterData[0]);

      // Storing sensor data
      for (let i = 0; i < sensorData.length; i++) {
        odour.push(sensorData[i].voc);
        temperature.push(sensorData[i].temp);
        humidity.push(sensorData[i].humidity);
        sound.push(sensorData[i].sound);
        label.push(
          moment(sensorData[i].createdat)
            .utc()
            .format("MMM Do hh:mm a")
        );
      }

      // Storing counter data
      for (let i = 0; i < counterData.length; i++) {
        counter.push(counterData[i].in);
      }

      console.log(counter);

      let newData = {
        labels: label,
        datasets: [
          {
            label: "Counter",
            fill: false,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#be55ed",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: counter
          },
          {
            label: "Odour",
            fill: false,
            borderColor: config.colors.red,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#be55ed",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: odour
          },
          {
            label: "Temperature",
            fill: false,
            borderColor: config.colors.orange,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#be55ed",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: temperature
          },
          {
            label: "Humidity",
            fill: false,
            borderColor: config.colors.green,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#be55ed",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: humidity
          },
          {
            label: "Sound",
            fill: false,
            borderColor: config.colors.purple,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#be55ed",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: sound
          }
        ]
      };
      this.combineLineGraph.chartData = newData;
    }
  },
  created() {
    this.callSensorData();
    this.callCounterData();
    this.callFormData();
  }
};
</script>
<style></style>
