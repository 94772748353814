<template>
  <!-- first big row -->
  <div class="row">
    <div class="col-lg-12" style="text-align: center; padding-top: 20%">
        <base-button class="btn btn-primary" @click="reset(null)">
        Reset Aduan
        </base-button>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";

export default { 

    data: function(){
    return{
    };
  },
  methods:{
      reset(n){

          let data = n;
          console.log(data)
          fetch("https://staging.vectolabs.com/report/reset", {
                method: "POST",
                headers: {
                "Content-Type": "application/json"
                },
                mode: "no-cors",
                body: JSON.stringify(data) //hantar data je weeh !!!! lk
            })
                .then(data => {
                console.log(data);
                });
        swal({
            title: `Aduan telah diset semula`,
            buttonsStyling: false,
            confirmButtonClass: "btn btn-primary",
            type: "success",
            timer: 2000
        })

        this.$router.push("/dashboard");
      }
  }
}
</script>
<style>

</style>
