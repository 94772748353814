import { Scatter, mixins } from 'vue-chartjs'
// const { reactiveProp } = mixins

export default {
  name: 'scatter',
  extends: Scatter,
  mixins: [mixins.reactiveProp],
  props: {
    extraOptions: Object,
    gradientColors: {
      type: Array,
      default: () => [
        'rgba(72,72,176,0.2)',
        'rgba(72,72,176,0.0)',
        'rgba(119,52,169,0)'
      ],
      validator: val => {
        return val.length > 1;
      }
    },
    gradientStops: {
      type: Array,
      default: () => [1, 0.4, 0],
      validator: val => {
        return val.length > 1;
      }
    }
  },
  data() {
    return {
      ctx: null
    };
  },
  methods: {
    updateGradients(chartData) {
      if (!chartData) return;
      const ctx =
        this.ctx || document.getElementById(this.chartId).getContext('2d');
      const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      this.gradientStops.forEach((stop, index) => {
        gradientStroke.addColorStop(stop, this.gradientColors[index]);
      });

      chartData.datasets.forEach(set => {
        if (!set.backgroundColor) {
          set.backgroundColor = gradientStroke;
        }
      });
    },
    beforeRender: function(chartData) {
      if (chartData.config.radarOptions.showAllTooltips) {
        // create an array of tooltips
        // we can't use the chart tooltip because there is only one tooltip per chart
        chartData.pluginTooltips = [];
        chartData.config.data.datasets.forEach(function(dataset, i) {
          chartData.getDatasetMeta(i).data.forEach(function(sector, j) {
            chartData.pluginTooltips.push(new Chart.Tooltip({
              _chart: chartData.chartData,
              _chartInstance: chartData,
              _data: chartData.data,
              _options: chartData.radarOptions.tooltips,
              _active: [sector]
            }, chartData));
          });
        });
  
        // turn off normal tooltips
        chart.radarOptions.tooltips.enabled = false;
      }
    },
    afterDraw: function(chartData, easing) {
      if (chartData.config.radarOptions.showAllTooltips) {
        // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
        if (!chartData.allTooltipsOnce) {
          if (easing !== 1)
            return;
            chartData.allTooltipsOnce = true;
        }
  
        // turn on tooltips
        chartData.radarOptions.tooltips.enabled = true;
        Chart.helpers.each(chartData.pluginTooltips, function(tooltip) {
          tooltip.initialize();
          tooltip.update();
          // we don't actually need this since we are not animating tooltips
          tooltip.pivot();
          tooltip.transition(easing).draw();
        });
        chartData.radarOptions.tooltips.enabled = false;
      }
    }
  },
  mounted () {
    this.$watch(
      'chartData',
      (newVal, oldVal) => {
        this.updateGradients(newVal);
        if (!oldVal) {
          this.renderChart(this.chartData, this.extraOptions);
        }
      },
      { immediate: true }
    );
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    // this.renderChart(this.chartData, this.extraOptions)
  },
  watch: {
    
  }
}